.terms-card {
  max-width: 800px;
  margin: 2rem auto;
  padding: 1rem;
}

.section-selector {
  margin-bottom: 2rem;
}

.section-selector select {
  width: 100%;
  padding: 0.8rem;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: white;
}

.flashcard {
  background-color: white;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 2rem;
  min-height: 300px;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  transition: transform 0.3s ease;
}

.flashcard:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0,0,0,0.15);
}

.card-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 250px;
  text-align: center;
}

.term {
  font-size: 2rem;
  color: #333;
  margin-bottom: 1rem;
}

.definition {
  font-size: 1.2rem;
  line-height: 1.6;
  color: #666;
  max-width: 600px;
  margin: 0 auto;
}

.placeholder-message {
  text-align: center;
  padding: 3rem;
  color: #666;
  background-color: #f5f5f5;
  border-radius: 8px;
}

.placeholder-message h3 {
  font-size: 1.2rem;
  font-weight: normal;
}

.navigation-buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 2rem;
  padding: 0 1rem;
}

.navigation-buttons button {
  padding: 0.5rem 1.5rem;
  border: none;
  border-radius: 4px;
  background-color: #007bff;
  color: white;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.navigation-buttons button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.navigation-buttons button:hover:not(:disabled) {
  background-color: #0056b3;
}

.progress-indicator {
  font-size: 1rem;
  color: #666;
}
