.score-tracker {
  margin: 20px 0;
  text-align: center;
}

.progress-bar {
  width: 100%;
  height: 10px;
  background: #eee;
  border-radius: 5px;
  overflow: hidden;
}

.progress {
  height: 100%;
  background: #4bb543;
  transition: width 0.3s ease;
}

.final-score {
  padding: 20px;
  text-align: center;
}

.score-summary {
  margin: 20px 0;
  padding: 15px;
  background: #f5f5f5;
  border-radius: 8px;
}

.answer-review {
  margin-top: 30px;
  text-align: left;
}

.answer-item {
  margin: 15px 0;
  padding: 15px;
  border-radius: 8px;
}

.answer-item.correct {
  background-color: rgba(75, 181, 67, 0.1);
  border: 1px solid #4bb543;
}

.answer-item.incorrect {
  background-color: rgba(255, 0, 0, 0.1);
  border: 1px solid #ff0000;
}

.retry-btn {
  margin-top: 20px;
  padding: 10px 20px;
  background: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.retry-btn:hover {
  background: #0056b3;
} 