.exam-mode {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  background: white;
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0,0,0,0.1);
}

/* Exam Selector Styles - Matching VocabMode */
.exam-selector {
  margin-bottom: 20px;
}

.exam-selector select {
  width: 100%;
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 8px;
  font-size: 1rem;
  background-color: white;
  cursor: pointer;
}

/* Rest of your existing styles... */
.questions {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.question-card {
  background: white;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 20px;
}

.question-text {
  font-size: 1.1em;
  margin-bottom: 15px;
}

.question-number {
  font-weight: bold;
  margin-right: 8px;
}

.options {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.option {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.option:hover {
  background-color: #f5f5f5;
}

.submit-button {
  width: 100%;
  padding: 15px;
  background: #2196f3;
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 1.1rem;
  cursor: pointer;
  margin-top: 20px;
  transition: background 0.2s;
}

.submit-button:hover {
  background: #1976d2;
}

.results {
  padding: 20px;
  background: #f8f9fa;
  border-radius: 8px;
}

.answer-review {
  margin-top: 20px;
}

.answer-item {
  margin: 15px 0;
  padding: 15px;
  border-radius: 8px;
  background: white;
}

.answer-item.correct {
  border-left: 4px solid #4caf50;
}

.answer-item.incorrect {
  border-left: 4px solid #f44336;
}

.retry-button {
  padding: 12px 24px;
  background: #2196f3;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  margin-top: 15px;
}

.placeholder-message {
  text-align: center;
  padding: 40px;
  color: #666;
}

.score-summary {
  background: #f8f9fa;
  padding: 20px;
  border-radius: 8px;
  margin: 20px 0;
  text-align: center;
}

.score-summary p {
  margin: 10px 0;
  font-size: 1.2rem;
  font-weight: bold;
}

.result-actions {
  display: flex;
  gap: 15px;
  justify-content: center;
  margin-top: 30px;
}

.retry-button,
.flashcard-button {
  padding: 12px 24px;
  border: none;
  border-radius: 8px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.2s;
}

.retry-button {
  background-color: #2196f3;
  color: white;
}

.retry-button:hover {
  background-color: #1976d2;
}

.flashcard-button {
  background-color: #4caf50;
  color: white;
}

.flashcard-button:hover {
  background-color: #388e3c;
}

@media (max-width: 600px) {
  .exam-mode {
    padding: 15px;
  }
  
  .question-card {
    padding: 15px;
  }
  
  .option {
    padding: 8px;
  }

  .result-actions {
    flex-direction: column;
  }

  .retry-button,
  .flashcard-button {
    width: 100%;
  }
} 