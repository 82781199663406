.flashcard-exam-mode {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  background: white;
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0,0,0,0.1);
}

.top-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  gap: 20px;
  flex-wrap: wrap;
}

.score-container {
  position: static;
  transform: none;
  flex: 1;
  text-align: center;
  min-width: 200px;
}

.toggle-score-button {
  padding: 8px 16px;
  background-color: #2196f3;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  white-space: nowrap;
}

.score-box {
  background: #f5f5f5;
  padding: 10px 20px;
  border-radius: 8px;
  margin: 0;
  width: 100%;
}

.score-box p {
  margin: 5px 0;
  font-weight: bold;
}

.navigation {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin-top: auto;
  padding: 20px 0;
}

.nav-button {
  padding: 8px 16px;
  background-color: #ccc;
  color: black;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.nav-button:not(:disabled) {
  background-color: #2196f3;
  color: white;
}

.flashcard {
  background: #fff;
  border-radius: 8px;
  padding: 30px;
  box-shadow: 0 2px 8px rgba(0,0,0,0.1);
  margin-bottom: 20px;
}

.card-content {
  text-align: center;
}

.card-content h3 {
  margin-bottom: 20px;
  font-size: 1.3em;
}

.options {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin: 20px 0;
}

.option-btn {
  padding: 12px 20px;
  border: 2px solid #ddd;
  border-radius: 6px;
  background: white;
  cursor: pointer;
  font-size: 1em;
  text-align: left;
  transition: all 0.2s;
}

.option-btn:hover:not(:disabled) {
  background: #f5f5f5;
}

.option-btn.correct {
  border-color: #4caf50;
  background: #e8f5e9;
}

.option-btn.incorrect {
  border-color: #f44336;
  background: #ffebee;
}

.answer-feedback {
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid #eee;
}

.correct-text {
  color: #4caf50;
  font-weight: bold;
}

.incorrect-text {
  color: #f44336;
  font-weight: bold;
}

.correct-answer {
  margin: 10px 0;
  color: #4caf50;
}

.next-button, .retry-button {
  margin-top: 15px;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  background: #2196f3;
  color: white;
  cursor: pointer;
  font-size: 1em;
}

.next-button:hover, .retry-button:hover {
  background: #1976d2;
}

.navigation-controls {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.navigation-buttons {
  display: flex;
  align-items: center;
  gap: 15px;
}

.nav-button {
  padding: 8px 16px;
  background-color: #2196f3;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.nav-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.toggle-score-button {
  padding: 8px 16px;
  background-color: #2196f3;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.score-display {
  text-align: right;
  background: #f5f5f5;
  padding: 10px;
  border-radius: 4px;
}

.score-display p {
  margin: 5px 0;
  font-weight: bold;
}

.exam-selector {
  margin-bottom: 20px;
}

.exam-selector select {
  width: 100%;
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 8px;
  font-size: 1rem;
  background-color: white;
  cursor: pointer;
}

@media (max-width: 768px) {
  .top-bar {
    flex-direction: column;
    align-items: stretch;
  }

  .score-container {
    order: 2;
  }

  .toggle-score-button {
    width: 100%;
  }
} 