.app-header {
  background: #2196f3;
  color: white;
  position: sticky;
  top: 0;
  z-index: 1000;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.header-content {
  max-width: 1200px;
  margin: 0 auto;
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo {
  color: white;
  text-decoration: none;
  font-size: 1.5rem;
  font-weight: bold;
}

.nav-menu {
  display: flex;
  gap: 1.5rem;
}

.nav-item {
  color: white;
  text-decoration: none;
  padding: 0.5rem;
  transition: opacity 0.2s;
}

.nav-item:hover {
  opacity: 0.8;
}

.nav-item.active {
  font-weight: bold;
  border-bottom: 2px solid white;
}

.menu-toggle {
  display: none;
  flex-direction: column;
  justify-content: space-between;
  width: 30px;
  height: 21px;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  z-index: 10;
}

.menu-toggle span {
  width: 100%;
  height: 3px;
  background: white;
  border-radius: 10px;
  transition: all 0.3s linear;
  position: relative;
  transform-origin: 1px;
}

/* Mobile Styles */
@media (max-width: 768px) {
  .menu-toggle {
    display: flex;
  }

  .menu-toggle.open span:first-child {
    transform: rotate(45deg);
  }

  .menu-toggle.open span:nth-child(2) {
    opacity: 0;
  }

  .menu-toggle.open span:nth-child(3) {
    transform: rotate(-45deg);
  }

  .nav-menu {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    flex-direction: column;
    background: #2196f3;
    padding: 1rem;
    gap: 0.5rem;
  }

  .nav-menu.open {
    display: flex;
  }

  .nav-item {
    padding: 1rem;
    width: 100%;
    text-align: center;
    border-bottom: 1px solid rgba(255,255,255,0.1);
  }

  .nav-item:last-child {
    border-bottom: none;
  }
} 