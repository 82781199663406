.menu-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 40px 20px;
}

.menu-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 20px;
  margin-top: 30px;
}

.menu-card {
  background: white;
  border-radius: 12px;
  padding: 24px;
  text-decoration: none;
  color: inherit;
  transition: transform 0.2s, box-shadow 0.2s;
  box-shadow: 0 2px 8px rgba(0,0,0,0.1);
}

.menu-card:hover {
  transform: translateY(-4px);
  box-shadow: 0 4px 12px rgba(0,0,0,0.15);
}

.menu-icon {
  font-size: 2.5em;
  display: block;
  margin-bottom: 16px;
}

.menu-card h2 {
  margin: 0 0 12px 0;
  color: #2196f3;
}

.menu-card p {
  margin: 0;
  color: #666;
  line-height: 1.4;
} 