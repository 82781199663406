.vocab-card {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  background: white;
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0,0,0,0.1);
}

/* Mode Selection Styles */
.mode-selector {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-bottom: 20px;
}

.mode-btn {
  padding: 10px 20px;
  border: 2px solid #2196f3;
  border-radius: 8px;
  background: white;
  color: #2196f3;
  cursor: pointer;
  font-size: 1rem;
  transition: all 0.2s ease;
}

.mode-btn.active {
  background: #2196f3;
  color: white;
}

.mode-btn:hover {
  background: #2196f3;
  color: white;
}

/* Section Selector Styles */
.section-selector {
  margin-bottom: 20px;
}

.section-selector select {
  width: 100%;
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 8px;
  font-size: 1rem;
}

/* Flashcard Styles */
.flashcard {
  background: white;
  border: 1px solid #ddd;
  border-radius: 12px;
  padding: 30px;
  margin: 20px 0;
  min-height: 200px;
  cursor: pointer;
  transition: transform 0.2s;
}

.flashcard:hover {
  transform: translateY(-2px);
}

.card-content {
  text-align: center;
}

.word {
  font-size: 2rem;
  color: #2196f3;
}

.definition {
  font-size: 1.2rem;
  margin-bottom: 15px;
}

.example {
  color: #666;
  font-style: italic;
}

/* Fill in the Blank Styles */
.fill-blank-mode {
  padding: 20px;
  background: #f8f9fa;
  border-radius: 12px;
  margin: 20px 0;
}

.sentence {
  font-size: 1.2rem;
  margin-bottom: 20px;
  line-height: 1.6;
}

.options {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 15px;
  margin-bottom: 20px;
}

.option-btn {
  padding: 15px;
  border: 2px solid #ddd;
  border-radius: 8px;
  background: white;
  cursor: pointer;
  font-size: 1rem;
  transition: all 0.2s;
}

.option-btn:hover:not(:disabled) {
  background: #f0f0f0;
}

.option-btn.correct {
  border-color: #4caf50;
  background: #e8f5e9;
  color: #2e7d32;
}

.option-btn.incorrect {
  border-color: #f44336;
  background: #ffebee;
  color: #c62828;
}

.feedback {
  text-align: center;
  margin-top: 20px;
  padding: 15px;
  border-radius: 8px;
}

.correct-text {
  color: #4caf50;
  font-weight: bold;
}

.incorrect-text {
  color: #f44336;
  font-weight: bold;
}

/* Navigation Buttons */
.next-button {
  display: block;
  width: 100%;
  padding: 15px;
  background: #2196f3;
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 1.1rem;
  cursor: pointer;
  margin-top: 20px;
  transition: background 0.2s;
}

.next-button:hover {
  background: #1976d2;
}

/* Completion Message */
.completion-message {
  text-align: center;
  padding: 30px;
  background: #f8f9fa;
  border-radius: 12px;
}

.completion-message button {
  padding: 12px 24px;
  background: #2196f3;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  margin-top: 15px;
}

/* Loading and Error States */
.placeholder-message {
  text-align: center;
  padding: 40px;
  color: #666;
}

@media (max-width: 600px) {
  .options {
    grid-template-columns: 1fr;
  }
  
  .mode-selector {
    flex-direction: column;
  }
  
  .mode-btn {
    width: 100%;
  }
} 